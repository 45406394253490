import {useRef} from 'react';

import Navbar from './../../UI/Navbar/Navbar';
import Sidebar from './../../UI/Sidebar/Sidebar';
import PageControl from './../../UI/PageControl/PageControl';
import Footer from './../../UI/Footer/Footer';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

import styles from './PageFive.module.css';

const PageFive = (props) => {

    const messagesToAlternate = [
        [
            {
                message: <p>
                    Very knowledgeable and walked thru the whole system and
                    set us up with the right package.Very excited to have our
                    system installed and will definitely be recommending this
                    system. Evan did a great job and was very approachable
                    and was able to give us peace of mind.
                </p>,
                family: <h3>The Cerda Family</h3>,
                location: <h4>Oklahoma City, OK</h4>
            }
        ],
        [
            {
                message: 
                    <p>
                        I am one day from powering up! I am so eager to have
                        my green home. My representative from start to finish
                        has been PJ Bachman. He has been dilligent in
                        helping me at every step of the way. When there was
                        paper or computer work, he was without fail sitting
                        at the table with me.
                    </p>,
                    family: <h3>THE JONES FAMILY</h3>,
                    location: <h4>MIDLAND, TX</h4>
            }
        ],
        [
            {
                message: 
                <p>
                    I had 3 different companies come quote me for solar, they all
                    promised me the "best deal" and then never followed
                    through. PAIC came out and gave me a quote, and did
                    everything, install and all within a week. Everything
                    was done exactgly how I wanted it done.
                </p>,
                family: <h3>THE CASTILLO FAMILY</h3>,
                location: <h4>MIDLAND, TX</h4>
            }
        ],
        [
            {
                message: 
                    <p>
                        Met Ricky at a friends house and talked
                        about solar panels. As the conversation
                        continued, my husband and I got more
                        interested. The more Ricky talked about
                        the items, the more effect it took on us. I 
                        had a very pleasant conversation with him 
                        and all that he presented!
                    </p>,
                    family: <h3>THE BRYANT FAMILY</h3>,
                    location: <h4>OKLAHOMA CITY, OK</h4>
            }
        ],
        [
            {
                message:
                    <p>
                        PAIC just installed solar panels on our 
                        home in Norman, OK. We are happy to
                        recommend them. The salesman, PJ, 
                        explained everything professionally and
                        thoroughly. He answered all of our questions
                        too. The installation was professional
                        and no mess was left behind.
                        Can't wait to start saving money!
                    </p>,
                family: <h3>THE PETERSON FAMILY</h3>,
                location: <h4>OKLAHOMA CITY, OK</h4>
            }
        ],
        [
            {
                message: 
                    <p>Travis and his team did an outstanding job.
                        Good customer service and everything is 
                        neat and taken care of. Everything is up and 
                        ready to go! I recomend him to everybody,
                        they are fabulous!
                    </p>,
                family: <h3>THE WILLIAMS FAMILY</h3>,
                location: <h4>MIDLAND, TX</h4>
            }
        ],
    ]

    const pageFiveSliderRef = useRef();

    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 1200,
        arrows: false,
        dots: false,
        lazyLoad: true,
        slidesToScroll: 1
      };

    const leftButtonClickHandler = () => {
        pageFiveSliderRef.current.slickPrev();
    }
    const rightButtonClickHandler = () => {
        pageFiveSliderRef.current.slickNext();
    }

    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler()
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }

    
    return(
        <div className={styles.pageFiveBackgroundImage} style={{backgroundImage: `url(https://i.ibb.co/R696Ws9/Page-Five-Background-Image.png)`}}>
            <Navbar spanColor={'#ccff00'} displayColor={'white'}/>
            <div className={styles.pageFiveSideBar}><Sidebar message={"TESTIMONIALS"}/></div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                pageThreeBullet={'#ccff00'}
            />
            <div className={styles.pageFiveIconBig}><i class="fas fa-quote-left"></i></div>
            <div className={styles.pageFiveIconSmall}><i class="fas fa-quote-right"></i></div>
            <div className={styles.pageFiveMessageTitle}>
                <h1><span>MEET OUR RAVING FANS</span> TRUTH IS, WE ARE YOUR RAVING FANS TOO</h1>
            </div>
            <div className={styles.pageFiveAlternatingMessages}>
                <Slider {...settings} ref={pageFiveSliderRef}>
                    <div className={styles.pageFiveAlternatingMessage}>
                        <div className={styles.pageFiveAlternatingMessageSmall}>
                             {messagesToAlternate[0][0].family}
                             {messagesToAlternate[0][0].location}
                        </div>
                        {messagesToAlternate[0][0].message}
                        <div className={styles.pageFiveAlternatingMessageBig}>
                            {messagesToAlternate[0][0].family}
                            {messagesToAlternate[0][0].location}
                        </div>
                    </div>
                    <div className={styles.pageFiveAlternatingMessage}>
                    <div className={styles.pageFiveAlternatingMessageSmall}>
                             {messagesToAlternate[1][0].family}
                             {messagesToAlternate[1][0].location}
                        </div>
                        {messagesToAlternate[1][0].message}
                        <div className={styles.pageFiveAlternatingMessageBig}>
                            {messagesToAlternate[1][0].family}
                            {messagesToAlternate[1][0].location}
                        </div>
                    </div>
                    <div className={styles.pageFiveAlternatingMessage}>
                    <div className={styles.pageFiveAlternatingMessageSmall}>
                             {messagesToAlternate[2][0].family}
                             {messagesToAlternate[2][0].location}
                        </div>
                        {messagesToAlternate[2][0].message}
                        <div className={styles.pageFiveAlternatingMessageBig}>
                            {messagesToAlternate[2][0].family}
                            {messagesToAlternate[2][0].location}
                        </div>
                    </div>
                    <div className={styles.pageFiveAlternatingMessage}>
                    <div className={styles.pageFiveAlternatingMessageSmall}>
                             {messagesToAlternate[3][0].family}
                             {messagesToAlternate[3][0].location}
                        </div>
                        {messagesToAlternate[3][0].message}
                        <div className={styles.pageFiveAlternatingMessageBig}>
                            {messagesToAlternate[3][0].family}
                            {messagesToAlternate[3][0].location}
                        </div>
                    </div>
                    <div className={styles.pageFiveAlternatingMessage}>
                    <div className={styles.pageFiveAlternatingMessageSmall}>
                             {messagesToAlternate[4][0].family}
                             {messagesToAlternate[4][0].location}
                    </div>
                        {messagesToAlternate[4][0].message}
                        <div className={styles.pageFiveAlternatingMessageBig}>
                            {messagesToAlternate[4][0].family}
                            {messagesToAlternate[4][0].location}
                        </div>
                        </div>
                    <div className={styles.pageFiveAlternatingMessage}>
                    <div className={styles.pageFiveAlternatingMessageSmall}>
                             {messagesToAlternate[5][0].family}
                             {messagesToAlternate[5][0].location}
                        </div>
                        {messagesToAlternate[5][0].message}
                        <div className={styles.pageFiveAlternatingMessageBig}>
                            {messagesToAlternate[5][0].family}
                            {messagesToAlternate[5][0].location}
                        </div>
                    </div>
                </Slider>
            </div>
            <div className={styles.pageFiveButtonsContainer}>
                    <div className={styles.pageFiveButtonOne} onClick={leftButtonClickHandler}><button><i class='fas fa-chevron-left' /></button></div>
                    <div className={styles.pageFiveButtons} onClick={rightButtonClickHandler}><button><i class='fas fa-chevron-right' /></button></div>
            </div>
            <Footer stateColor={'#ccff00'}/>
        </div>
    )
}

export default PageFive