import {useRef, useCallback, useEffect} from 'react';

import PageOne from './../Pages/PageOne/PageOne'
import PageTwo from './../Pages/PageTwo/PageTwo'
import PageThree from './../Pages/PageThree/PageThree'
import PageFour from './../Pages/PageFour/PageFour'
import PageFive from './../Pages/PageFive/PageFive'
import PageSix from './../Pages/PageSix/PageSix'
import PageSeven from './../Pages/PageSeven/PageSeven'
import PageEight from './../Pages/PageEight/PageEight'
import PageNine from './../Pages/PageNine/PageNine'
import PageTen from './../Pages/PageTen/PageTen'
import PageEleven from './../Pages/PageEleven/PageEleven';
import PageTwelve from './../Pages/PageTwelve/PageTwelve';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

import styles from './PageContainer.module.css';


const PageContainer = () => {
    const sliderRef = useRef();

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        speed: 700,
        swipeToSlide: true,
        touchThreshold: 15
    }

    const upArrowChangeHandler = () => {
        sliderRef.current.slickPrev();
    }
    const downArrowChangeHandler = () => {
        sliderRef.current.slickNext();
    }

    const bookAppointmentHandler = () => {
        sliderRef.current.slickGoTo(9);
    }
    
    const scroll = useCallback(
        y => {
        if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))){
          if (y > 0) {
            return sliderRef.current && sliderRef.current.slickNext(); /// ? <- using description below 
          } else {
            return sliderRef.current && sliderRef.current.slickPrev();
          }
        }
        },
        [sliderRef]
      );
     useEffect(() => {
        window.addEventListener("wheel", e => {
          scroll(e.deltaY);
        });
      }, [scroll]);

    return(
        <Slider {...settings} ref={sliderRef}>
             {/* <div id='slider' className={styles.pageContainerPage}>
                    <PageTwelve 
                        upArrowChangeHandler={upArrowChangeHandler} 
                        downArrowChangeHandler={downArrowChangeHandler}
                    />
                </div> */}
                <div id='slider' className={styles.pageContainerPage}>
                    <PageOne 
                        upArrowChangeHandler={upArrowChangeHandler} 
                        downArrowChangeHandler={downArrowChangeHandler}
                    />
                </div>
                <div id='slider' className={styles.pageContainerPage}>
                        <PageTwo 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                            bookAppointmentHandler={bookAppointmentHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageFive 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageFour 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageThree 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageSix 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageEight 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageNine 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                {/* <div id='slider' className={styles.pageContainerPage}>
                    <PageTwelve 
                        upArrowChangeHandler={upArrowChangeHandler} 
                        downArrowChangeHandler={downArrowChangeHandler}
                    />
                </div> */}
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageSeven 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageEleven
                            upArrowChangeHandler={upArrowChangeHandler}
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
                <div id='slider'
                    className={styles.pageContainerPage}>
                        <PageTen 
                            upArrowChangeHandler={upArrowChangeHandler} 
                            downArrowChangeHandler={downArrowChangeHandler}
                        />
                </div>
        </Slider>
    )
}

export default PageContainer;