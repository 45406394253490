import {Fragment} from 'react';

import styles from './Sidebar.module.css';

const Sidebar = (props) => {


    return(
        <Fragment>
            <p className={styles.sidebarMessage} style={{color: props.displayColor}}>{props.message}</p>
        </Fragment>
    )
}

export default Sidebar;