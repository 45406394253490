// import CardClickHereIcon from './../../Logos/CardClickHereIcon.png'
import styles from './Card.module.css'

const Card = (props) => {

    
    return(
        <div className={styles.cardContainer}>
            {/* <div><img className={styles.clickHereIcon} src={CardClickHereIcon} alt='click here' /></div> */}
            <div className={styles.doubleTapIcon}><img  src='https://i.ibb.co/sKxKLj1/double-Tap.png' alt='double tap' /></div>
            <div className={styles.cardContainerImage}>
                <img src={props.imgSrc} alt='instagram' />
            </div>
        </div>
    )
}

export default Card;