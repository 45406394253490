import { useState, useEffect } from 'react';

import Navbar from './../../UI/Navbar/Navbar';
import Footer from './../../UI/Footer/Footer';
import PageControl from './../../UI/PageControl/PageControl';
import SideBar from './../../UI/Sidebar/Sidebar';
import Card from './../../UI/Card/Card';


import HTMLFlipBook from 'react-pageflip';

import styles from './PageSix.module.css';


const PageSix = (props) => {

const [zIndexArray, setZIndexArray] = useState(['1','2','3','4','5','6','7','8','9','10']);

    const cardTenClickHandler = () => {
        setZIndexArray(['1','2','3','4','5','6','7','8','9','10']);
    }
    const cardNineClickHandler = () => {
        setZIndexArray(['3','4','5','6','7','8','9','10','11','10']);
    }
    const cardEightClickHandler = () => {
        setZIndexArray(['4','5','6','7','8','9','10','11','10','9']);
    }
    const cardSevenClickHandler = () => {
        setZIndexArray(['5','6','7','8','9','10','11','10','9','8']);
    }
    const cardSixClickHandler = () => {
        setZIndexArray(['6','7','8','9','10','11','10','9','8','7']);
    }
    const cardFiveClickHandler = () => {
        setZIndexArray(['7','8','9','10','11','10','9','8','7','6']);
    }
    const cardFourClickHandler = () => {
        setZIndexArray(['8','9','10','11','10','9','8','7','6','5']);
    }
    const cardThreeClickHandler = () => {
        setZIndexArray(['9','10','11','10','9','8','7','6','5','4']);
    }
    const cardTwoClickHandler = () => {
        setZIndexArray(['10','11','10','9','8','7','6','5','4','3']);
    }
    const cardOneClickHandler = () => {
        setZIndexArray(['11','10','9','8','7','6','5','4','3','2']);
    }

    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler()
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
      
      function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
      }
    const { width } = useWindowDimensions();
    let imageWidth;
    let imageHeight;
    if (width <= 1250 && width > 1000){
        imageWidth = 820;
        imageHeight = 690;
    }else if (width < 1000 && width > 800) {
        imageWidth= 680;
        imageHeight = 600;
    }else if(width <= 800 && width > 700) {
        imageWidth = 550;
        imageHeight = 525;
    }else if (width <=700 && width > 500) {
        imageWidth = 375;
        imageHeight = 360;
    }else if (width <=500) {
        imageWidth = 350;
        imageHeight = 325;
    }
    

    

    return(
        <div className={styles.pageSixContainer}>
            <Navbar displayColor={'black'} spanColor={'#38b6ff'}/>
            <div className={styles.pageSixSideBar}><SideBar message={'SOCIALS'} displayColor={'black'}/></div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                displayColor={'black'}
                pageSixBullet={'#38b6ff'}
            />

            <div className={styles.pageSixMessage}>
                <p>LET'S MAKE IT</p>
                <h1><span>INSTAGRAM</span> OFFICIAL</h1>
            </div>
            <div className={styles.pageSixFlipBookContainerSmall} >
                <HTMLFlipBook width={imageWidth} height={imageHeight}>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/6tZF0C1/IG1.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/hWYt6zf/IG2.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/njBrZpr/IG3.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/BwgVgdv/IG4.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/grzQyZ6/IG5.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/WDvJ4tS/IG6.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/m4654D4/IG7.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/vXMBbZk/IG8.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/YXHNFxX/IG9.png' />
                    </div>
                    <div className={styles.pageSixFlipBookCard}>
                        <Card imgSrc='https://i.ibb.co/4T5PN7F/IG10.png' />
                    </div>
                </HTMLFlipBook>
                <div className={styles.iconContainer}>
                        <div><a href='https://www.facebook.com/HelloPaic'><i class="fab fa-facebook-f"></i></a></div>
                        <div><a href='https://www.instagram.com/paicsolar/'><i class="fab fa-instagram"></i></a></div>
                        <div><i class="fab fa-pinterest-p"></i></div>
                        <div><a href='https://www.linkedin.com/company/paicsolar/mycompany/'><i class="fab fa-linkedin"></i></a></div>
                        <div><a href='https://g.page/r/CSo84qanvwEGEAE'><i class="fab fa-google"></i></a></div>
            </div>
            </div>

            <div className={styles.pageSixCardContainerBig} >
                <div className={styles.pageSixCard} style={{zIndex: zIndexArray[9], backgroundColor: 'white'}} onClick={cardTenClickHandler} onMouseOver={cardTenClickHandler}>
                    <Card imgSrc='https://i.ibb.co/6tZF0C1/IG1.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[8], marginLeft: '6.25vw', backgroundColor: 'white'}} onClick={cardNineClickHandler} onMouseOver={cardNineClickHandler}>
                    <Card imgSrc='https://i.ibb.co/hWYt6zf/IG2.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[7] ,marginLeft: '12.5vw', backgroundColor: 'white'}} onClick={cardEightClickHandler} onMouseOver={cardEightClickHandler}>
                    <Card imgSrc='https://i.ibb.co/njBrZpr/IG3.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[6],marginLeft: '18.75vw', backgroundColor: 'white'}} onClick={cardSevenClickHandler} onMouseOver={cardSevenClickHandler}>
                    <Card imgSrc='https://i.ibb.co/BwgVgdv/IG4.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[5],marginLeft: '25vw', backgroundColor: 'white'}} onClick={cardSixClickHandler} onMouseOver={cardSixClickHandler}>
                    <Card imgSrc='https://i.ibb.co/grzQyZ6/IG5.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[4],marginLeft: '31.25vw', backgroundColor: 'white'}} onClick={cardFiveClickHandler} onMouseOver={cardFiveClickHandler}>
                    <Card imgSrc='https://i.ibb.co/WDvJ4tS/IG6.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[3],marginLeft: '37.5vw', backgroundColor: 'white'}} onClick={cardFourClickHandler} onMouseOver={cardFourClickHandler}> 
                    <Card imgSrc='https://i.ibb.co/m4654D4/IG7.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[2],marginLeft: '43.75vw', backgroundColor: 'white'}} onClick={cardThreeClickHandler} onMouseOver={cardThreeClickHandler}>
                    <Card imgSrc='https://i.ibb.co/vXMBbZk/IG8.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[1],marginLeft: '50vw', backgroundColor: 'white'}} onClick={cardTwoClickHandler} onMouseOver={cardTwoClickHandler}>
                    <Card imgSrc='https://i.ibb.co/YXHNFxX/IG9.png'/>
                </div>
                <div className={styles.pageSixCard} style={{ zIndex: zIndexArray[0],marginLeft: '56.25vw', backgroundColor: 'white'}} onClick={cardOneClickHandler} onMouseOver={cardOneClickHandler}>
                    <Card imgSrc='https://i.ibb.co/4T5PN7F/IG10.png'/>
                </div>
            </div>
            <Footer stateColor={'#38b6ff'} offStateColor={'#022b49'}/>
        </div>

    )
}

export default PageSix;