import styles from './TextBox.module.css';


const TextBox = (props) => {

    return(
        <div className={styles.textBoxContainer}>
            <div className={styles.textBox}>
            {props.header}
            {props.message}
            </div>
        </div>
    )

}

export default TextBox;