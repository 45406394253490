import { useState, useEffect } from 'react';


import styles from './PictureSlideShow.module.css';

const PictureSlideShow = (props) => {

    const [showImageCounter, setShowImageCounter] = useState(0);
    const [visibleImage, setVisibleImage] = useState('https://i.ibb.co/K5ndQ75/Slide-Show-P1.png');

    var imageArray = ['https://i.ibb.co/K5ndQ75/Slide-Show-P1.png','https://i.ibb.co/sFZRx49/Slide-Show-P2.png', 'https://i.ibb.co/Jc8YtBN/Slide-Show-P3.png','https://i.ibb.co/0m4NYCx/Slide-Show-P4.png','https://i.ibb.co/VNvXCCW/Slide-Show-P5.png','https://i.ibb.co/qWCvgkd/Slide-Show-P6.png','https://i.ibb.co/kgqBSw6/Slide-Show-P7.png',
        'https://i.ibb.co/W6s2Pcd/Slide-Show-P8.png','https://i.ibb.co/j6GxqmB/Slide-Show-P9.png','https://i.ibb.co/2g3KX0b/Slide-Show-P10.png','https://i.ibb.co/kyT318M/Slide-Show-P11.png','https://i.ibb.co/M2TBgdL/Slide-Show-P12.png','https://i.ibb.co/f4DK7F1/Slide-Show-P13.png','https://i.ibb.co/qWtzHMj/Slide-Show-P14.png','https://i.ibb.co/DbWgNk5/Slide-Show-P15.png','https://i.ibb.co/FzzwWWd/Slide-Show-P16.png'];

    useEffect(() => {
        setTimeout(() => {
            if(showImageCounter === 15){
                setShowImageCounter(0);
            }else{
                setShowImageCounter(showImageCounter + 1);
            }
            setVisibleImage(imageArray[showImageCounter]);
        }, 3000);

    });


    return(
        <div className={styles.pictureSlideShowContainer}>
            <div className={styles.pictureSlideShowPictures}>
                <img src={visibleImage} alt='solar panels on roof' />
            </div>
        
        </div>
    )
}

export default PictureSlideShow;