import { useState} from 'react';

import Navbar from './../../UI/Navbar/Navbar';
import Footer from './../../UI/Footer/Footer';
import Sidebar from './../../UI/Sidebar/Sidebar';
import PageControl from './../../UI/PageControl/PageControl';

import styles from './PageFour.module.css';


const PageFour = (props) => {
    const pageFourAlternatingText = [
        [
            {
                text: 
                    <p>
                        Unused electricity flows through a utility meter 
                        into the grid. When electricity is needed, it can 
                        be drawn from the grid.
                    </p>
            }
        ], 
        [
            {
                text: 
                <p>
                This current goes to the inverter which 
                converts the direct current into alternating current, 
                and sends the AC to your electric box to power your home.
            </p>
        
            }
        ],
        [
            {
                text: 
                <p>
                        Sunlight hits the solar panels and generates 
                        electric current.
                    </p>
                   
            }
        ],
        [
            {
                text: 
                <p>
                        The AC electricity goes from the inverter 
                        to the breaker box which directs 
                        the current to appliances in your home
                    </p>
            }
        ]
    ]


    const [colorOne, setColorOne] = useState('');
    const [colorTwo, setColorTwo] = useState('');
    const [colorThree, setColorThree] = useState('');
    const [colorFour, setColorFour] = useState(''); 

    const buttonOneClickHandler = () => {
        setColorTwo(false);
        setColorThree(true);
        setColorFour(false);
        setColorOne(false);
    }
    const buttonTwoClickHandler = () => {
        setColorOne(false);
        setColorTwo(true);
        setColorThree(false);
        setColorFour(false);
    }
    const buttonThreeClickHandler = () => {
        setColorOne(false);
        setColorTwo(false);
        setColorThree(false);
        setColorFour(true);
    }
    const buttonFourClickHandler = () => {
        setColorOne(true);
        setColorTwo(false);
        setColorThree(false);
        setColorFour(false);
    }
    const buttonResetHandler = () => {
        setColorOne('');
        setColorTwo('');
        setColorThree('');
        setColorFour('');
    }

    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler()
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }
    return(
        <div className={styles.pageFourContainer}>
        <Navbar displayColor={'black'} spanColor={'#38b6ff'}/>
        <div className={styles.pageFourBackgroundImageBig} style={{backgroundImage: `url(https://i.ibb.co/VBbGvJP/Page-Four-Background-Image.png)`, 
        filter: colorOne || colorTwo||colorThree||colorFour ? 'grayscale(100%)':'' }}> </div>
        <div className={styles.pageFourMinimizedBackgroundImgSmall} style={{backgroundImage: `url(https://i.ibb.co/mqZBqPn/Page-Four-Minimized-Background-Img.png)`, 
        filter: colorOne || colorTwo||colorThree||colorFour ? 'grayscale(100%)':'' }}> </div>
            <div className={styles.pageFourMainContainer}>
            <div className={styles.pageFourSideBar}><Sidebar message={'WHAT WE DO'} displayColor={'#022b49'}/></div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                displayColor={'black'}
                pageFourBullet={'#38b6ff'}
            />
            <div className={styles.pageFourMessage}>
                <h1>HOW IT ALL WORKS</h1>
                <p>
                    Consider us your solar gurus. We are 
                    here to answer any questions that you 
                    might have. We know it can be confusing 
                    at time so we broke it down into four 
                    steps for you!    
                </p>
                <div className={styles.clickableButtons}>
                    <img className={styles.clickableButton} src='https://i.ibb.co/j68F8hF/Page-Four-Solar-Panel-Icon.png' onMouseOver={buttonOneClickHandler} onMouseOut={buttonResetHandler} alt='Midland Texas Button' />
                    <img className={styles.clickableButton} src='https://i.ibb.co/Xtk61qc/Page-Four-Electric-Icon.png' onMouseOver={buttonTwoClickHandler}  onMouseOut={buttonResetHandler} alt='Odessa Texas Button' />
                    <img className={styles.clickableButton} src='https://i.ibb.co/QJNqfdP/Page-Four-Light-Bulb-Icon.png'  onMouseOver={buttonThreeClickHandler} onMouseOut={buttonResetHandler} alt='Oklahoma City Button' />
                    <img className={styles.clickableButton} src='https://i.ibb.co/SstJ9Z1/Page-Four-Meter-Icon.png'  onMouseOver={buttonFourClickHandler} onMouseOut={buttonResetHandler} alt='Dallas Button' />
                </div>
                <div className={styles.clickableButtonText} >
                    {colorOne && pageFourAlternatingText[0][0].text}
                    {colorTwo && pageFourAlternatingText[1][0].text}
                    {colorThree && pageFourAlternatingText[2][0].text}
                    {colorFour && pageFourAlternatingText[3][0].text}
                </div>    
            </div>
            {colorThree && <span style={{filter: 'drop-shadow( 0 0 .5rem #38b6ff) saturate(150%)'}}> 
                <img
                    className={styles.pageFourSolarPanels}
                    src='https://i.ibb.co/F614jc4/Page-Four-Solar-Panels.png'
                    alt='solar panels Midland'
                />
            </span>}
            {(colorThree === false) && < span
                style={{
                        filter: 'grayscale(100%)'
                }}
                >
                <img
                    className={styles.pageFourSolarPanels}
                    src='https://i.ibb.co/F614jc4/Page-Four-Solar-Panels.png'
                    alt='solar panels Odessa'
                />
            </span> }
            {(colorThree === null) && < span
                style={{
                        filter: 'grayscale(100%)'
                }}
                >
                <img
                    className={styles.pageFourSolarPanels}
                    src='https://i.ibb.co/F614jc4/Page-Four-Solar-Panels.png'
                    alt='solar panels Oklahoma City'
                />
            </span> }
            <div className={styles.pageFourObjects}>
            {colorTwo && 
            <span className={styles.coverPlateBoxShadow}
            style={{filter: 'drop-shadow( 0 0 .5rem #38b6ff) saturate(150%)'}}
            >
            <img 
                className={styles.pageFourCoverPlate} 
                src='https://i.ibb.co/PwMMBtd/PFONE.png' 
                alt='Cover Plate Dallas' />
            </span>}
            {(colorTwo === false) &&
            <span className={styles.coverPlateBoxShadow}
            style={{filter: 'grayscale(100%)'}}
            >
            <img 
                className={styles.pageFourCoverPlate} 
                src='https://i.ibb.co/PwMMBtd/PFONE.png' 
                alt='Cover Plate Texas' />
            </span>}
            {colorTwo === '' && 
            <span className={styles.coverPlateBoxShadow}
            >
            <img 
                className={styles.pageFourCoverPlate} 
                src='https://i.ibb.co/PwMMBtd/PFONE.png'
                alt='Cover Plate Texas' />
            </span>}
            
            <img 
                className={styles.pageFourLightFixture} 
                src='https://i.ibb.co/jGbkkZ5/Page-Four-Light-Fixture.png'
                style={{boxShadow: colorFour ? '0 0 1rem .5rem #38b6ff': ' '}}  
            alt='meter' />
            <img 
                className={styles.pageFourMeter} 
                src='https://i.ibb.co/nRx0SfT/Page-Four-Meter.png' 
                style={{
                    boxShadow: colorOne ? '0 0 1rem .5rem #38b6ff': ' '}}  alt='meter' />
            </div>
            <Footer stateColor={'#38b6ff'} offStateColor={'#022b49'}/>
            </div>
        </div>
    )
}

export default PageFour;