import { useState } from 'react';

import Navbar from './../../UI/Navbar/Navbar';
import Sidebar from './../../UI/Sidebar/Sidebar';
import PageControl from './../../UI/PageControl/PageControl';
import Footer from './../../UI/Footer/Footer';
import Modal from 'react-modal';

import styles from './PageEleven.module.css';

Modal.setAppElement('#root');

const PageEleven = (props) => {

    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler()
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }

    return(
        <div className={styles.pageElevenContainer}>
            <Navbar displayColor={'black'} spanColor={'#38b6ff'}/>
            <div className={styles.pageElevenSideBar}><Sidebar message={"LET'S TALK"} /></div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                displayColor={'black'}
                pageElevenBullet={'#ccff00'}
            />
            <div className={styles.pageElevenMessage}>
                <p className={styles.pageElevenSubTitle}>WHAT'S NEXT?</p>
                <div className={styles.pageElevenSchedule}>
                    <h1><span>SCHEDULE</span> A CONSULTATION</h1>
                    <p className={styles.pageElevenSubMessage}>
                        Now that you're a solar expert, what's the next step? We want to chat with 
                        you! Select an in-home, zoom, or phone call visit so we can talk about 
                        discussing your new solar panels.
                    </p>
                </div>
            </div>
            <div className={styles.pageElevenSelectorContainer}>
                <div className={styles.pageElevenSelector}>
                    <div className={styles.pageElevenSelectorImageContainer}>
                        <img src='https://i.ibb.co/rp7mrH4/Page-Eleven-Selector-One.png' alt='Midland Texas' />
                    </div>
                    <div>
                        <h4>IN-HOME CONSULT</h4>
                        <div className={styles.pageElevenSelectorDetails}>
                            <p className={styles.pageElevenSelectorDetailsTime}>1 hour </p>
                            <p>FREE</p>
                            <button onClick={openModal}>BOOK</button>
                        </div>
                    </div>
                </div>
                <div className={styles.pageElevenSelector}>
                    <div className={styles.pageElevenSelectorImageContainer}><img src='https://i.ibb.co/g7hxNRQ/Page-Eleven-Selector-Two.png' alt='Zoom Chat Texas' /></div>
                    <div>
                        <h4>ONLINE ZOOM CONSULT</h4>
                        <div className={styles.pageElevenSelectorDetails}>
                            <p className={styles.pageElevenSelectorDetailsTime}>30 min</p>
                            <p>FREE</p>
                            <button onClick={openModal}>BOOK</button>
                        </div>
                    </div>
                </div>
                <div className={styles.pageElevenSelector}>
                    <div className={styles.pageElevenSelectorImageContainer}><img src='https://i.ibb.co/tY0gbM9/Page-Eleven-Selector-Three.png' alt='Texas Phone' /></div>
                    <div>
                        <h4>PHONE CONSULT</h4>
                        <div className={styles.pageElevenSelectorDetails}>
                            <p className={styles.pageElevenSelectorDetailsTime}>1 hour</p>
                            <p>FREE</p>
                            <button onClick={openModal}>BOOK</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal 
                isOpen={modalIsOpen}
                style={
                    {
                        overlay: {
                            backdropFilter: 'opacity(90%)'
                        },
                        content: {
                            top: '6vh',
                            bottom: '6vh',
                            left: '10vw',
                            right: '10vw',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            boxShadow: '0 0 1vw 0 black'
                        }
                }
            }
                >
                    <button onClick={closeModal} className={styles.closeModal}>X</button>
                <iframe title='Book Now' width='100%' height='100%' src='https://peter-paicsolar.zohobookings.com/portal-embed#/customer/paicsolar' frameborder='0' allowfullscreen='' > </iframe>
            </Modal>
            <Footer stateColor={'#38b6ff'} offStateColor={'#022b49'}/>
        </div>
    )
}

export default PageEleven;