import { useRef, useState, useEffect} from 'react'

import Navbar from './../../UI/Navbar/Navbar';
import Footer from './../../UI/Footer/Footer';
import PageControl from './../../UI/PageControl/PageControl';
import Sidebar from '../../UI/Sidebar/Sidebar';
import TextBox from '../../UI/TextBox/TextBox';
import Modal from 'react-modal';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

import styles from './PageThree.module.css';


const PageThree = (props) => {
    const textBoxData = 
    [
        [
            {
                header: <h1><span>INCREASE</span> PROPERTY VALUE</h1>,
                message: <p>
                Your Home is already worth a ton. By adding 
                solar into the mix, you're increasing your 
                property value by almost 15%. Prospective home 
                owners and appraisers take notice when 
                evaluating a house with solar. The benefits and 
                savings speak for themselves so let solar do the 
                work for you.
            </p>
            }
        ],
        [
            {
                header: <h1><span>AVOID</span> SPIKING RATES</h1>,
                message:<p>
                Over the past decade, residential electric rates 
                have increased by over 3% annually. Adding 
                solar panels to your home is an excellent way to 
                hedge your utility costs. solar Powered Energy
                allows you to keep your electric rate fixed for 
                decades to come and avoid unpredictable spikes 
                that you would otherwise have zero say or 
                control over.
            </p>
            }
        ],
        [
            {
                header: <h1><span>PROTECT</span> THE ENVIRONMENT</h1>,
                message: <p>
                Solar Energy is one of the top and most 
                innovative ways to reduce your carbon 
                footprint. A typical residential solar panel
                system will eliminate three to four tons of
                carbon emissions each year; that's the
                equivalent of planting over 100 trees annually. 
                Solar is also non polluting and is a natural 
                recourse you can harness to do your part. 
            </p>
            }
        ],
        [
            {
                header: <h1><span>INVEST</span> IN YOUR FAMILY</h1>,
                message: <p>
                Going solar is one of the best investments a 
                homeowner can make, and the returns prove 
                that! Over eight years, the average ROI for solar 
                customers is over 20%. On top of eliminating
                spikes in energy rates, most home owners have
                a lower monthly rate than they did with the
                electric company, and that price is locked in.
                </p>
            }
        ]
    ]

    const pageThreeSliderRef = useRef();

    const rotateRightHandler = () => {
        pageThreeSliderRef.current.slickNext();
    }
    const rotateLeftHandler = () => {
       pageThreeSliderRef.current.slickPrev();
    }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }
      
      function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
      }
    const { width } = useWindowDimensions();

    
    let slidesToShow;

    if ( width > 1000) {
        slidesToShow = 3;
    }
    else if(width <= 1000 && width > 700){
        slidesToShow = 2;
    }else if(width <= 700){
        slidesToShow = 1;
    }else {
        slidesToShow = 3;
    }

    const settings = {
      infinite: true,
      slidesToShow: slidesToShow,
      speed: 1200,
      arrows: false,
      dots: false,
    };

    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler()
    } 
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }
    const modal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }

    const [ openModal, setOpenModal ] = useState(false);

    return(
        <div className={styles.pageThreeBackgroundImage} style={{backgroundImage: `url(https://i.ibb.co/C9JGbkK/page-Three-Background-Image.png)`}}>
            <Navbar spanColor={'#ccff00'} displayColor={'white'}/>
            <div className={styles.pageThreeSideBar}><Sidebar message={'WHY SOLAR'} /></div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                pageFiveBullet={'#ccff00'}    
            />
            <div className={styles.pageThreeMessage}>BECAUSE<br/> <span>YOU'RE WORTH IT</span></div>

            <div className={styles.pageThreeTextBoxContainer} >
                <Slider {...settings} ref={pageThreeSliderRef}>
                    <div className={styles.pageThreeTextBox}>
                        <TextBox 
                            header={textBoxData[0][0].header}
                            message={textBoxData[0][0].message}
                        />
                    </div>
                    <div className={styles.pageThreeTextBox}>
                        <TextBox 
                            header={textBoxData[1][0].header}
                            message={textBoxData[1][0].message}
                        />
                    </div>
                    <div className={styles.pageThreeTextBox}>
                        <TextBox 
                            header={textBoxData[2][0].header}
                            message={textBoxData[2][0].message}
                        />
                    </div>
                    <div className={styles.pageThreeTextBox}>
                        <TextBox
                            header={textBoxData[3][0].header}
                            message={textBoxData[3][0].message}
                        />
                    </div>
                    </Slider>
                    </div>
            <div className={styles.pageThreeButton}>
                <button onClick={modal}>READY FOR MY QUOTE</button>
            </div>
            <div className={styles.pageThreeSlideShowControlContainer}>
                <div className={styles.pageThreeSlideShowControl}>
                    <div onClick={rotateLeftHandler}><button><i class='fas fa-chevron-left fa-2x'/></button></div>
                    <div onClick={rotateRightHandler}><button><i class='fas fa-chevron-right fa-2x'/></button></div>
                </div>
            </div>
            <Footer stateColor={'#ccff00'}/>
            <Modal 
                isOpen={openModal}
                style={
                    {
                        overlay: {
                            backdropFilter: 'opacity(90%)'
                        },
                        content: {
                            top: '6vh',
                            bottom: '6vh',
                            left: '10vw',
                            right: '10vw',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            boxShadow: '0 0 1vw 0 black'
                        }
                }
            }
                >
                    <button onClick={closeModal} className={styles.closeModal}>X</button>
                <iframe title='Book Now' width='100%' height='100%' src='https://peter-paicsolar.zohobookings.com/portal-embed#/customer/paicsolar' frameborder='0' allowfullscreen='' > </iframe>
            </Modal>
        </div>
    )
}

export default PageThree;