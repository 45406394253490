import PageContainer from "./Components/PageContainer/PageContainer";


function App() {
  return (
    <div className="App">
      <PageContainer />
    </div>
  );
}

export default App;
