
import { useState } from 'react';
import PictureSlideShow from '../../PictureSlideShow/PictureSlideShow';
import Footer from '../../UI/Footer/Footer';
import PageControl from '../../UI/PageControl/PageControl';
import Navbar from '../../UI/Navbar/Navbar';
import SideBar from '../../UI/Sidebar/Sidebar';
import Modal from 'react-modal';

import styles from './PageTwo.module.css';

const PageTwo = (props) => {
    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler();
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }
    const bookAppointmentHandler = () => {
        props.bookAppointmentHandler();
    }

    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }
    const [modalIsOpen, setModalIsOpen] = useState(false);
    return(
        <div className={styles.pageTwoContainer}>
            <Navbar spanColor={'#38b6ff'} displayColor={'black'}/>
            <div className={styles.pageTwoSideBar}><SideBar message='WHAT WE DO' displayColor={'black'}/></div>
            <div className={styles.pageTwoInfo}>
                <h1>OUR MISSION IS TO PROVIDE THE HIGHEST
                    LEVEL OF INFORMATION AND SERVICES FOR YOU
                </h1>
                <p>Residential and commerical solar, carports, roofing,
                    top tier panels, and premier support.
                </p>
                <div className={styles.pageTwoButton}>
                <button style={{color: '#38b6ff'}} onClick={openModal}>PUT US TO THE TEST!</button>
                </div>
            </div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                displayColor={'black'}
                pageTwoBullet={'#38b6ff'}    
            />
            <div className={styles.pageTwoPictureSlideShowContainer} >
            <PictureSlideShow onQuoteMe={bookAppointmentHandler}/>
            </div>
            <Footer stateColor={'#38b6ff'} offStateColor={'black'}/>
            <Modal 
                isOpen={modalIsOpen}
                style={
                    {
                        overlay: {
                            backdropFilter: 'opacity(90%)'
                        },
                        content: {
                            top: '6vh',
                            bottom: '6vh',
                            left: '10vw',
                            right: '10vw',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            boxShadow: '0 0 1vw 0 black'
                        }
                }
            }
                >
                    <button onClick={closeModal} className={styles.closeModal}>X</button>
                <iframe title='Book Now' width='100%' height='100%' src='https://peter-paicsolar.zohobookings.com/portal-embed#/customer/paicsolar' frameborder='0' allowfullscreen='' > </iframe>
            </Modal>
        </div>
        

    )
}

export default PageTwo;