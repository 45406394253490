import { useState } from 'react';
import Navbar from './../../UI/Navbar/Navbar';
import Footer from './../../UI/Footer/Footer';
import Sidebar from './../../UI/Sidebar/Sidebar';
import PageControl from './../../UI/PageControl/PageControl';
import Modal from 'react-modal';

import styles from './PageEight.module.css';

Modal.setAppElement('#root');

const PageEight = (props) => {

    const globalUpArrowHandler = goingUp => {
        props.upArrowChangeHandler(goingUp)
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }

    return(
        <div className={styles.pageEightBackgroundImage} style={{backgroundImage: `url(https://i.ibb.co/1q8gK5K/Page-Eight-Background-Image.png)`}}>
            <Navbar displayColor={'#022b49'} spanColor={'#38b6ff'}/>
            <div className={styles.pageEightSideBar}><Sidebar message={'PARTNERS'} displayColor={'#022b49'}/></div>
            <PageControl 
            onUpArrow={globalUpArrowHandler} 
            onDownArrow={globalDownArrowHandler}
            displayColor={'#022b49'}
            pageSevenBullet={'#38b6ff'}
            />
            <div className={styles.pageEightMessage}>
                <p className={styles.pageEightSubTitle}>JOIN FORCES</p>
                <h1>TEAM WORK <br/>MAKES THE <span><br/>DREAM WORK</span></h1>
                <p className={styles.pageEightText}>
                    We are always looking for new friends and 
                    professional relationships to build. Do you 
                    have an operational sales group or 
                    contractor presence? Reach out to us so we 
                    can talk about all the ways that we can 
                    grow together.
                </p>
                <button onClick={openModal}>PARTNER WITH US</button>
            </div>
            <Modal 
                isOpen={modalIsOpen}
                style={
                    {
                        overlay: {
                            backdropFilter: 'opacity(90%)'
                        },
                        content: {
                            top: '6vh',
                            bottom: '6vh',
                            left: '10vw',
                            right: '10vw',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            boxShadow: '0 0 1vw 0 black'
                        }
                }
            }
                >
                    <button onClick={closeModal} className={styles.closeModal}>X</button>
                    <iframe 
                        frameborder="0"
                        height='100%'
                        width='99%'
                        border='none' 
                        src='https://forms.zohopublic.com/paicnation/form/PARTNERWITHUS/formperma/i1IIyL5MrecMzXBSuXm4RPwVJ6B_BycI0ZO53RgZxQQ'
                        title='Partner With Us'
                    />
            </Modal>
            <Footer stateColor={'#38b6ff'} offStateColor={'#022b49'}/>
        </div>

    )
}

export default PageEight;
