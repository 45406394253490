
import styles from './Nav.module.css'
const Navbar = (props) => {

    
  

    return(
        <div className={styles.navbarContainer} style={{color: props.displayColor}}>
            <ul className={styles.navbar}>
                <li className={styles.logos}>
                    <ul>
                        <li>
                            {props.displayColor === 'white' && <img className={styles.arrows} src='https://i.ibb.co/jWnGwfd/Navbar-Arrows-Green.png' alt='Arrow Logo' />}
                            {props.displayColor === 'black' && <img className={styles.arrows} src='https://i.ibb.co/Z24Q95r/Navbar-Arrows.png' alt='Arrow Logo' />}
                            {props.displayColor === 'heart' && <img className={styles.heart} src='https://i.ibb.co/7gSL6pG/Heart.png' alt='Arrow Logo' />}
                        </li>
                            {props.displayColor === 'black' && <img className={styles.logo} src='https://i.ibb.co/nsvfkFK/PaicLogo.png' alt='PAIC Logo'/>}
                            {props.displayColor === 'white' && <img className={styles.logo} src='https://i.ibb.co/9qLJM2P/White-Paic-Logo.png' alt='PAIC Logo'/>}
                            {props.displayColor === 'heart' && <img className={styles.logo} src='https://i.ibb.co/9qLJM2P/White-Paic-Logo.png' alt='PAIC Logo'/>}
                    </ul>
                </li>
                <li id='callUs'  className={styles.callUs}>   
                    <a href='tel:4323150086'>CALL US: <span style={{color: props.spanColor}}> (432) 315-0086</span></a>
                </li>
                <li className={styles.iconContainer}>
                    <a href='https://www.facebook.com/HelloPaic'><i class="fab fa-facebook-f"></i></a>
                    <a href='https://www.instagram.com/paicsolar/'><i class="fab fa-instagram fa-2x"></i></a>
                    <a href='https://www.pinterest.com/paicsolar/'><i class="fab fa-pinterest-p fa-2x"></i></a>
                    <a href='https://www.linkedin.com/company/paicsolar/mycompany/'><i class="fab fa-linkedin fa-2x"></i></a>
                    <a href='https://g.page/r/CSo84qanvwEGEAE'><i class="fab fa-google fa-2x"></i></a>
                </li>
                <li className={styles.hatContainer}>
                    <img className={styles.hat} src='https://i.ibb.co/ggntqw7/SwagHat.png' alt='hat with swag' />
                </li>
                <li className={styles.hamburgerBar}>
                    <i class="fas fa-bars fa-lg"></i>
                </li>
            </ul>
        </div>
                   
    )
}

export default Navbar;