import Navbar from '../../UI/Navbar/Navbar'
import Sidebar from '../../UI/Sidebar/Sidebar';
import Footer from '../../UI/Footer/Footer';
import styles from './PageOne.module.css';
import PageControl from '../../UI/PageControl/PageControl';
import Modal from 'react-modal';

import { useState } from 'react';


const PageOne = (props) => {
    let count = 0;

    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler();
    }
    const globalDownArrowHandler = () => {
        if(count > 0) {
                props.downArrowChangeHandler();
        }
        count++;
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [secondModalIsOpen, setSecondModalIsOpen ] = useState(false);
    
    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }

    const secondOpenModal = () => {
        setSecondModalIsOpen(true);
    }
    const secondCloseModal = () => {
        setSecondModalIsOpen(false);
    }

    return(
        <div className={styles.pageOneBackground} style={{backgroundImage: `url(https://i.ibb.co/hY4q9zx/Page-One-Background-Image.jpg)`}} >
            <Navbar displayColor={'white'}/>
            <div className={styles.pageOneSideBar}><Sidebar message={'INTRODUCE'}/></div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                displayColor={'white'}
                pageOneBullet={'#ccff00'}    
            />
            <div className={styles.backgroundInfo}>
                <div className={styles.backgroundInfoParagraph}>
                <div className={styles.backgroundInfoP}>
                    <p>Welcome to PAIC</p>
                    <p className={styles.backgroundInfoParagraphPremier}>Premier Aesthetix Installation & Construction</p>
                </div>
                </div>
                <div>
                    <h1>WE HELP<span> THE SUN</span> HELP YOU</h1>
                </div>
                
                <div><button onClick={openModal} >READY FOR MY QUOTE</button></div>
                <div><button onClick={secondOpenModal} style={{marginTop: '2.5vh'}}>READY TO PARTNER</button></div>
                
            </div>

            <div className={styles.backgroundInfoIpad}>
                <div className={styles.backgroundInfoParagraphIpad}>
                     <div>
                    <h1>WE HELP<span> THE SUN</span> HELP YOU</h1>
                </div>
                <div>
                    <div><button onClick={openModal} >READY FOR MY QUOTE</button></div>
                    <div><button onClick={secondOpenModal} style={{marginTop: '2vh'}}>READY TO PARTNER</button></div>
                </div>
                <div className={styles.backgroundInfoPIpad}>
                    <p>Welcome to PAIC</p>
                    <p className={styles.backgroundInfoParagraphPremierIpad}>Premier Aesthetix Installation & Construction</p>
                </div>
                </div>
                <Modal 
                isOpen={secondModalIsOpen}
                style={
                    {
                        overlay: {
                            backdropFilter: 'opacity(90%)'
                        },
                        content: {
                            top: '6vh',
                            bottom: '6vh',
                            left: '10vw',
                            right: '10vw',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            boxShadow: '0 0 1vw 0 black'
                        }
                }
            }
                >
                    <button onClick={secondCloseModal} className={styles.closeModal}>X</button>
                    <iframe 
                        frameborder="0"
                        height='100%'
                        width='99%'
                        border='none' 
                        src='https://forms.zohopublic.com/paicnation/form/PARTNERWITHUS/formperma/i1IIyL5MrecMzXBSuXm4RPwVJ6B_BycI0ZO53RgZxQQ'
                        title='Partner With Us'
                    />
            </Modal>
            <Modal 
                isOpen={modalIsOpen}
                style={
                    {
                        overlay: {
                            backdropFilter: 'opacity(90%)'
                        },
                        content: {
                            top: '6vh',
                            bottom: '6vh',
                            left: '10vw',
                            right: '10vw',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            boxShadow: '0 0 1vw 0 black'
                        }
                }
            }
                >
                    <button onClick={closeModal} className={styles.closeModal}>X</button>
                <iframe title='Book Now' width='100%' height='100%' src='https://peter-paicsolar.zohobookings.com/portal-embed#/customer/paicsolar' frameborder='0' allowfullscreen='' > </iframe>
            </Modal>
                
            </div>
            <Footer stateColor={'#ccff00'}/>
        </div>
    

    )
}

export default PageOne;