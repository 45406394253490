import Navbar from './../../UI/Navbar/Navbar';
import Footer from './../../UI/Footer/Footer';
import Sidebar  from '../../UI/Sidebar/Sidebar';
import PageControl from '../../UI/PageControl/PageControl';

import styles from './PageTen.module.css';

const PageTen = (props) => {

    const globalUpArrowHandler = goingUp => {
        props.upArrowChangeHandler(goingUp)
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }

    
    return(
        <div className={styles.pageTenContainer}>
             <Navbar displayColor={'black'} spanColor={'#38b6ff'}/>
            <div className={styles.pageTenSideBar}><Sidebar message={'CONTACT'} displayColor={'black'}/></div>
            <PageControl 
            onUpArrow={globalUpArrowHandler} 
            onDownArrow={globalDownArrowHandler}
            displayColor={'black'}
            pageTenBullet={'#38b6ff'}
            />
            <div className={styles.pageTenMessage}>
                <p>
                    Give us a call
                </p>
                <h1>LET'S CHAT</h1>
            </div>
            <div className={styles.pageTenMapContainer}>
                <img className={styles.pageTenMap} src='https://i.ibb.co/2sgcRrG/Page-Ten-Map.png' alt='Solar Map'/>
            </div>
            <div className={styles.pageTenInformation}>
                <div className={styles.pageTenColumn}>
                    <div className={styles.pageTenColumnTitle}><h3>WRITE US</h3></div>
                    <div className={styles.pageTenColumnText}>
                        <h4>CUSTOMER SERVICE<span><a href='mailto:hello@paicsolar.com'> hello@paicsolar.com</a></span>
                        </h4>
                    </div>
                </div>
                <div className={styles.pageTenColumn}>
                    <div className={styles.pageTenColumnTitle}><h3 className={styles.pageTenCallUs}>CALL US</h3></div>
                    <div className={styles.pageTenColumnText}>
                        <h4>MIDLAND OFFICE
                            <span><a href='tel:4323150086'> (432)315-0086</a></span>
                        </h4>
                        <h4>OKLAHOMA OFFICE
                            <span><a href='tel:4052561581'> (405)256-1581</a></span>
                        </h4>
                    </div>
                </div>
                <div className={styles.pageTenColumnThree}>
                    <div className={styles.pageTenColumnTitle}><h3 className={styles.pageTenVisitUs}>VISIT US</h3></div>
                    <div className={styles.pageTenColumnTextThree}>
                        <h4>
                            MIDLAND OFFICE 
                            <span> <a href='https://www.google.com/maps/place/5002+Basin+St,+Midland,+TX+79703/@31.9600175,-102.1380372,17z/data=!3m1!4b1!4m5!3m4!1s0x86fbd9dc6ad06c77:0x65ea1410e6c215cc!8m2!3d31.960013!4d-102.1358485'>
                                  5002 Basin St. Midland, TX 79703</a></span>
                        </h4>
                        <h4>OKLAHOMA OFFICE 
                        <span> <a href='https://www.google.com/maps/place/2501+S+Vermont+Ave,+Oklahoma+City,+OK+73108/@35.4404724,-97.6006921,17z/data=!4m5!3m4!1s0x87b211c3721dce89:0xbbb11d01ee084338!8m2!3d35.440468!4d-97.5985034'>
                              2501 S Vermont Oklahoma City, OK 73107 </a></span>
                        </h4>
                    </div>
                </div>
            </div>
            <Footer stateColor={'#38b6ff'} offStateColor={'#022b49'}/>
        </div>
    )
}

export default PageTen;