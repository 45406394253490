import { useState, useRef } from 'react';

import Navbar from './../../UI/Navbar/Navbar';
import Footer from './../../UI/Footer/Footer';
import Sidebar from './../../UI/Sidebar/Sidebar';
import PageControl from './../../UI/PageControl/PageControl';
import Modal from 'react-modal';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import styles from './PageNine.module.css';

Modal.setAppElement('#root');

const PageNine = (props) => {

    const alternatingMessages = [
        [
            <div>
                    <p>
                    Get paid what you're worth by a company who knows it. 
                    We are a family here at PAIC and our family is growing. 
                    We currently have two locations in Oklahoma City and 
                    Midland, Texas.
                </p>
                <p>
                    We want you to join our family! We are hiring for sales 
                    reps, office staff, and other positions. We need your skills 
                    to bring our team to the next level.
                </p>
            </div>
        ],
        [
            <div>
                <p>Do more than work. Be a volunteer, be a nice person. Did you know that serving others can give 
                    you a sense of belonging and a sense of purpose, plus it makes you feel good!
                </p>
                <p>
                    We believe in hard work, but we also believe in the power of giving, or as we like to call it, "Powering Kindness!"
                    Let's join forces to make a difference in the environment and our 
                    communities, by giving more than we get.
                </p>
            </div>
        ],
        [
            <div>
                <p>
                    Are you ready for some excitement?! A career in sales is rewarding and challenging. As a
                    salesperson, you are a consultant, psychologist, team builder and customer service representative
                    all in one. Learn and expand on the skills you already have to become the best version of yourself 
                    so that you can help others. We provide the tools you'll need and the team spirit to cheer you 
                    through your sales goals.
                </p>
            </div>
        ],
        [
            <div>
                <p>
                    A good life begins with a good company. We value our team members and know that each person 
                    has a unique set of skills. WE want to help you strengthen these skills while also providing you with opporunities 
                     to grow in different positions.
                </p>
                <p>
                    At PAIC we promise to do our best to help you grow into the person you want to be. Let us help you further your career and help to pursue 
                    your career goals.
                </p>
            </div>
        ]
    ]

    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler();
    } 
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }
    const leftButtonClickHandler = () => {
        pageNineSliderRef.current.slickPrev();
    }
    const rightButtonClickHandler = () => {
        pageNineSliderRef.current.slickNext();
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);
    
    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }
    const settings = {
        infinite: true,
        slidesToShow: 1,
        speed: 1200,
        arrows: false,
        dots: false,
        lazyLoad: true,
        slidesToScroll: 1
      };

    const pageNineSliderRef = useRef();

    return (
        <div className={styles.pageNineBackgroundImage} style={{backgroundImage: `url(https://i.ibb.co/JyTcsjQ/Page-Nine-Background-Image.png)`}}>
            <Navbar spanColor={'#ccff00'} displayColor={'white'}/>
            <div className={styles.pageNineSidebar}><Sidebar message={'#PAICNATION'}/></div>
            <PageControl 
                onUpArrow={globalUpArrowHandler} 
                onDownArrow={globalDownArrowHandler}
                pageEightBullet={'#ccff00'}
                />
            <div className={styles.pageNineMessage}>
                <h4>JOIN OUR TEAM</h4>
                <h1>LIFE IS SHORT, <span>WORK <br/>SOMEWHERE AWESOME</span></h1>
                <button onClick={openModal}>APPLY</button>
                <div className={styles.alternatingMessagesContainer}>
                <Slider {...settings} ref={pageNineSliderRef}>
                    <div>
                        {alternatingMessages[0]}
                    </div>
                    <div>
                        {alternatingMessages[1]}
                    </div>
                    <div>
                        {alternatingMessages[2]}
                    </div>
                    <div>
                        {alternatingMessages[3]}
                    </div>
                </Slider>      
                </div>
            </div>
            <div className={styles.pageNineButtonContainer}>
                <div className={styles.pageNineButtonsLeft} onClick={leftButtonClickHandler}><button><i class='fas fa-chevron-left fa-2x' /></button></div>
                <div className={styles.pageNineButtons} onClick={rightButtonClickHandler}><button><i class='fas fa-chevron-right fa-2x' /></button></div>
            </div>
            <Modal 
                isOpen={modalIsOpen}
                style={
                    {
                        overlay: {
                            backdropFilter: 'opacity(90%)'
                        },
                        content: {
                            top: '6vh',
                            bottom: '6vh',
                            left: '10vw',
                            right: '10vw',
                            overflow: 'hidden',
                            backgroundColor: 'white',
                            boxShadow: '0 0 1vw 0 black',
                            position: 'absolute'
                        }
                }
            }
                >
                    <button onClick={closeModal} className={styles.closeModal}>X</button>
                    <iframe 
                        frameborder="0"
                        height='100%'
                        width='100%'
                        border='none' 
                        src="https://paicsolar.zohorecruit.com/forms/5ed2d23a86c784e9af929775c2a09a5d057628dc07bb4e66571e831bdd35a510"
                        title='Work With Us'
                    />
                </Modal>
            <Footer stateColor={'#ccff00'}/>
        </div>
        
    )
}

export default PageNine;