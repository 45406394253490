import styles from './PageControl.module.css';


const PageControl = (props) => {

    const upArrowHandler = () => {
        props.onUpArrow();
    }
    const downArrowHandler = () => {
        props.onDownArrow();
    }

    return(
            <div 
                className={styles.pageControlContainer}
                style={{
                    color: props.displayColor
                }}
            >
                <i onClick={upArrowHandler} class="fas fa-chevron-up fa-2x"></i>
                <ul>
                    <li style={{color: props.pageOneBullet}}></li>
                    <li style={{color: props.pageTwoBullet}}></li>
                    <li style={{color: props.pageThreeBullet}}></li>
                    <li style={{color: props.pageFourBullet}}></li>
                    <li style={{color: props.pageFiveBullet}}></li>
                    <li style={{color: props.pageSixBullet}}></li>
                    <li style={{color: props.pageSevenBullet}}></li>
                    <li style={{color: props.pageEightBullet}}></li>
                    <li style={{color: props.pageNineBullet}}></li>
                    <li style={{color: props.pageTenBullet}}></li>
                    <li style={{color: props.pageElevenBullet}}></li>

                </ul>
                <i onClick={downArrowHandler}class="fas fa-chevron-down fa-2x"></i>
            </div>
    )
}

export default PageControl;