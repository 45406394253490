import Navbar from './../../UI/Navbar/Navbar';
import Footer from './../../UI/Footer/Footer';
import Sidebar from './../../UI/Sidebar/Sidebar';
import PageControl from './../../UI/PageControl/PageControl';

import styles from './PageSeven.module.css';

const PageSeven = (props) => {
    const globalUpArrowHandler = () => {
        props.upArrowChangeHandler()
    }
    const globalDownArrowHandler = () => {
        props.downArrowChangeHandler();
    }

    return (
        <div className={styles.pageSevenBackgroundImage} style={{backgroundImage: `url(https://i.ibb.co/MgqXghX/Page-Seven-Background-Image.png)`}}>
        <Navbar spanColor={'#ccff00'} displayColor={'heart'}/>
        <div className={styles.pageSevenSideBar} ><Sidebar message={'PROJECTS'}/></div>
        <PageControl 
        onUpArrow={globalUpArrowHandler} 
        onDownArrow={globalDownArrowHandler}
        pageNineBullet={'#ccff00'}
        />
        <div className={styles.pageSevenLogoContainer}>
            <img className={styles.pageSevenLogo} src='https://i.ibb.co/Zh4jcV8/Page-Seven-Powering-Kindness-Logo.png' alt='Powering Kindness in Midland Tx' />
        </div>
        <div className={styles.pageSevenMessage}>
            <div className={styles.pageSevenText}>
                <p>
                    We would like to proudly introduce our "Powering Kindness
                    Initiative". We take tremendous pride in our communities and
                    recognize that charity starts in our backyards. We have had the 
                    honor of working with, volunteering and/or donating to "Show 
                    of Support-Military Hunt" here in Midland Tx, The Oklahoma
                    Regional Food Bank, and The Promised Land Dachshund
                    Sanctuary
                </p>
                <p>
                    We are always on the look out for more ways to help and serve.
                    If you know of any organizations or people that could use some
                    help, please let us know.
                </p>
            </div>
            <div className={styles.pageSevenRightTitleMessage}>
                <h1>ALWAYS <span> GIVE MORE</span> THAN YOU RECIEVE</h1>
            </div>
        </div>
        <Footer stateColor={'#ccff00'}/>
    </div>
    )
    
}

export default PageSeven;