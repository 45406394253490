

import styles from './Footer.module.css';


const Footer = (props) => {


    return (
        <div className={styles.footer}>
            <div className={styles.PAIC}>
                <p>@PAIC</p>
            </div>
        </div>
    )
}

export default Footer;